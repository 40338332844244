import React, { useCallback, useState } from 'react';
import Alert from '../../components/alert';
import useHttpClient from '../../hooks/useHttpClient';
import { Container, useTheme } from '@material-ui/core';
import { useStyles } from '../../hooks/useStyles';
import Loader from '../../components/loader';
import RewardCards from '../../components/rewardCards';
import ConfirmationDialog from '../../components/confirmationDialog';
import { CoinCount } from '../../components/coinCount';
import { useAuth } from '../../hooks/useAuth';
import { HTTP_ACTION } from '../../utils/constants';

const RedeemPage = () => {
  const { user } = useAuth();
  const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);
  const [selectedReward, setSelectedReward] = useState(false);
  const {
    data: rewardsData,
    loading: loadingRewards,
    error: rewardsError,
  } = useHttpClient({
    url: `/rewards`,
    initialRequest: true,
  });
  const {
    httpRequest: getUser,
    data: userData,
    loading: loadingUser,
    error: userError,
  } = useHttpClient({
    url: `/users?email=${user.email}`,
    initialRequest: true,
  });
  const {
    httpRequest: redeemRequest,
    loading: processingRedemption,
    error: redeemError,
  } = useHttpClient({
    httpAction: HTTP_ACTION.POST,
  });
  const theme = useTheme();
  const globalStyles = useStyles();

  const redeem = (reward) => {
    setSelectedReward(reward);
    setConfirmationIsOpen(true);
  };
  const handleClose = useCallback(() => {
    setConfirmationIsOpen(false);
  }, []);
  const handleConfirm = useCallback(async () => {
    await redeemRequest({ url: `/rewards/${selectedReward.id}/redeem` });
    getUser({});
    setConfirmationIsOpen(false);
  }, [selectedReward, getUser, redeemRequest]);
  return (
    <Container maxWidth={rewardsData && rewardsData.length > 1 ? 'md' : 'sm'}>
      <Alert className={globalStyles['mt-2']} show={rewardsError} severity='error'>
        Error retrieving rewards data.
      </Alert>
      <Alert className={globalStyles['mt-2']} show={userError} severity='error'>
        Error retrieving user data.
      </Alert>
      <Alert className={globalStyles['mt-2']} show={redeemError} severity='error'>
        {`Error redeeming reward: ${redeemError}`}
      </Alert>
      <Loader
        loading={loadingRewards || loadingUser || processingRedemption}
        size={100}
        color={theme.palette.primary.main}>
        {userData && (
          <CoinCount
            allowance={userData[0].account.allowance}
            loading={loadingRewards}
            savings={userData[0].account.savings}
            userId={userData[0].id}
          />
        )}
        <RewardCards rewards={rewardsData} redeem={redeem} savings={userData ? userData[0].account.savings : 0} />
        <ConfirmationDialog
          isOpen={confirmationIsOpen}
          handleClose={handleClose}
          handleConfirm={handleConfirm}
          okText={'Redeem'}
          title={`Confirm redemption for "${selectedReward.title}"`}
          body={`Are you sure you want to redeem ${selectedReward.cost} goatcoins for "${selectedReward.title}"?`}
        />
      </Loader>
    </Container>
  );
};

export default RedeemPage;
