import React, { useCallback, useEffect, useState } from 'react';
import Alert from '../../components/alert';
import useHttpClient from '../../hooks/useHttpClient';
import { CoinCount } from '../../components/coinCount';
import { Container, FormControlLabel, Grid, Radio, RadioGroup, useMediaQuery, useTheme } from '@material-ui/core';
import { FilterTable } from '../../components/filterTable';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useStyles } from '../../hooks/useStyles';

const getDateString = (date) => {
  let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  let month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
  let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

  return `${month}-${day}-${year}`;
};

const accountsColumns = [
  {
    cell: (row) => <Link to={`/users/${row.id}`}>{row.name}</Link>,
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Allowance',
    selector: 'account.allowance',
    sortable: true,
  },
  {
    name: 'Savings',
    selector: 'account.savings',
    sortable: true,
  },
];

const leaderboardColumns = [
  {
    cell: (row) => <Link to={`/users/${row.receiverId}`}>{row.receiverName}</Link>,
    name: 'Name',
    selector: 'receiverName',
    sortable: true,
  },
  {
    name: 'Amount Received',
    selector: 'amount',
    sortable: true,
  },
];

const accountsFilterFunction = (item, filterText) => item?.name?.toLowerCase().includes(filterText.toLowerCase());
const leaderboardFilterFunction = (item, filterText) =>
  item?.receiverName?.toLowerCase().includes(filterText.toLowerCase());

const HomePage = () => {
  const { user } = useAuth();
  const globalStyles = useStyles();
  const [userData, setUserData] = useState({ allowance: 0, savings: 0, id: '' });
  const [leaderboardScope, setLeaderboardScope] = useState('allTime');
  const theme = useTheme();
  const isSmallScreenSize = useMediaQuery(theme.breakpoints.only('sm'));

  const {
    data: accountsData,
    loading: accountsLoading,
    error: accountsError,
  } = useHttpClient({
    url: '/users',
    initialRequest: true,
  });

  const {
    data: leaderboardData,
    loading: leaderboardLoading,
    error: leaderboardError,
    httpRequest: leaderboardRequest,
  } = useHttpClient({
    url: '/leaderboard',
    initialRequest: true,
  });

  const updateLeaderboard = useCallback((event) => {
    const { value } = event.target;
    setLeaderboardScope(value);
  }, []);

  useEffect(() => {
    if (leaderboardScope === 'allTime') {
      leaderboardRequest({ url: '/leaderboard' });
    } else if (leaderboardScope === 'lastMonth') {
      let startDate = new Date();
      startDate.setDate(startDate.getDate() - 30);
      const startDateString = getDateString(startDate);

      let endDate = new Date();
      const endDateString = getDateString(endDate);

      leaderboardRequest({
        url: `/leaderboard?startDate=${startDateString}&endDate=${endDateString}`,
      });
    } else if (leaderboardScope === 'lastYear') {
      let startDate = new Date();
      startDate.setDate(startDate.getDate() - 365);
      const startDateString = getDateString(startDate);

      let endDate = new Date();
      const endDateString = getDateString(endDate);

      leaderboardRequest({
        url: `/leaderboard?startDate=${startDateString}&endDate=${endDateString}`,
      });
    }
  }, [leaderboardRequest, leaderboardScope]);

  useEffect(() => {
    if (!(user && accountsData)) return;

    const {
      account: { allowance, savings },
      id,
    } = accountsData.find((datum) => user?.email?.toLowerCase() === datum?.email?.toLowerCase()) ?? { account: {} };
    setUserData(allowance != null ? { allowance, savings, id } : undefined);
  }, [accountsData, user]);

  const LeaderboardSubHeader = useCallback(
    () => (
      <RadioGroup value={leaderboardScope} row onChange={updateLeaderboard}>
        <FormControlLabel value='allTime' control={<Radio />} label='All-time' />
        <FormControlLabel value='lastYear' control={<Radio />} label='12 Months' />
        <FormControlLabel value='lastMonth' control={<Radio />} label='1 Month' />
      </RadioGroup>
    ),
    [leaderboardScope, updateLeaderboard],
  );

  return (
    <>
      <Container maxWidth='sm'>
        <Grid item xs={12}>
          {userData && (
            <CoinCount
              allowance={userData.allowance}
              loading={accountsLoading}
              savings={userData.savings}
              userId={userData.id}
            />
          )}
        </Grid>
      </Container>
      <Container maxWidth={isSmallScreenSize ? 'sm' : 'md'}>
        <Alert className={globalStyles['mt-2']} show={accountsError || leaderboardError} severity='error'>
          Error retrieving data.
        </Alert>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FilterTable
              title={'Leaderboard'}
              data={leaderboardData}
              columns={leaderboardColumns}
              loading={leaderboardLoading}
              filter={true}
              filterFunction={leaderboardFilterFunction}
              defaultSortField={'amount'}
              defaultSortDir='desc'
              filterField='Name'
              SubHeaderComponent={LeaderboardSubHeader}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FilterTable
              title={'Accounts Summary'}
              data={accountsData}
              columns={accountsColumns}
              loading={accountsLoading}
              filter={true}
              filterFunction={accountsFilterFunction}
              defaultSortField={'receiverName'}
              filterField='Name'
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default HomePage;
