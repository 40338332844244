import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';

export const DonateBountyForm = ({ register, errors, allowance, bountyTitle }) => {
  return (
    <>
      <Box mt={2}>
        <Typography component='h1' variant='h5'>
          {`Donate to "${bountyTitle}" (Your Allowance: ${allowance})`}
        </Typography>
      </Box>

      <TextField
        inputRef={register({
          required: 'Donation is required',
          max: allowance,
          min: 1,
        })}
        type='number'
        variant='outlined'
        margin='normal'
        fullWidth
        id='donation'
        label='Donation'
        name='donation'
        autoFocus
        error={!!errors.donation}
        helperText={
          (errors.donation && errors.donation?.message) ||
          (errors.donation?.type === 'max' && `Cannot be greater than your allowance: ${allowance}`) ||
          (errors.donation?.type === 'min' && 'Must be greater than 0') ||
          'Amount will be deducted from your allowance'
        }
      />
    </>
  );
};

export default DonateBountyForm;
