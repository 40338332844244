import { Container, makeStyles, Paper } from '@material-ui/core';
import { GoogleLogin } from '@react-oauth/google';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useStyles } from '../../hooks/useStyles';

const useLocalStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(2),
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'gainsboro',
  },
}));

export const LoginPage = () => {
  const { loggedIn, loginOpts } = useAuth();
  const styles = useStyles();
  const localStyles = useLocalStyles();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (loggedIn) {
      const queryParams = queryString.parse(location.search);
      navigate(queryParams.redirect || '/');
    }
  }, [loggedIn, navigate, location.search]);

  return (
    <Container maxWidth='xs'>
      <Paper elevation={5} className={`${styles.paper} ${localStyles.paper}`}>
        <GoogleLogin {...loginOpts} />
      </Paper>
    </Container>
  );
};

export default LoginPage;
