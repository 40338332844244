import React, { Fragment, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { Controller } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import { Fab, Grid, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { v4 as uuidv4 } from 'uuid';

export const CompleteBountyForm = ({ register, errors, control, watch, bountyValue, bountyTitle, users, userData }) => {
  const [contributors, setContributors] = useState([uuidv4()]);

  const validateNames = () => {
    let errorMessage;

    if (new Set(watch('users')).size !== watch('users').length) {
      errorMessage = 'Duplicate contributor';
    } else if (
      watch('users').some((user) => {
        console.log(user, userData);
        return user.id === userData?.id;
      })
    ) {
      errorMessage = "Can't distribute coins to yourself";
    }

    return errorMessage;
  };

  const validateAmounts = () => {
    return watch('amounts').reduce((prev, curr) => parseInt(prev) + parseInt(curr), 0) !== bountyValue
      ? `Amount total must equal ${bountyValue}`
      : undefined;
  };

  const addContributor = () => {
    setContributors([...contributors, uuidv4()]);
  };

  return (
    <>
      <Box mt={2}>
        <Typography component='h1' variant='h5'>
          {`Complete the Bounty and Distribute ${bountyValue} goatcoin for "${bountyTitle}"`}
        </Typography>
      </Box>
      <Tooltip title='Add Contributor' aria-label='add contributor'>
        <Fab color='primary' onClick={addContributor} size='small'>
          <AddIcon />
        </Fab>
      </Tooltip>
      <Grid container spacing={2}>
        {contributors.map((uuid, index) => {
          return (
            <Fragment key={uuid}>
              <Grid item xs={6}>
                <Controller
                  render={({ onChange, ...props }) => (
                    <Autocomplete
                      autoHighlight
                      autoSelect
                      options={users}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Choose a contributor*'
                          variant='outlined'
                          fullWidth
                          margin='normal'
                          error={!!errors.users}
                          helperText={errors.users && errors.users[index]?.message}
                        />
                      )}
                      onChange={(e, data) => onChange(data)}
                      {...props}
                    />
                  )}
                  onChange={([, data]) => data}
                  name={`users[${index}]`}
                  control={control}
                  rules={{ validate: validateNames, required: 'Contributor is required' }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  inputRef={register({
                    validate: validateAmounts,
                    required: 'Amount is required',
                    min: 1,
                  })}
                  type='number'
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id={`amounts[${index}]`}
                  label='Amount*'
                  name={`amounts[${index}]`}
                  error={!!errors.amounts}
                  helperText={errors.amounts && errors.amounts[index] && errors.amounts[index]?.message}
                />
              </Grid>
            </Fragment>
          );
        })}
      </Grid>
    </>
  );
};

export default CompleteBountyForm;
