import React from 'react';
import { makeStyles, Button } from '@material-ui/core';

const useLocalStyles = makeStyles((theme) => ({
  textInput: {
    height: '2rem',
    width: '40%',
    borderRadius: '3px',
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
    border: '1px solid #e5e5e5',
    padding: theme.spacing(0, 4, 0, 2),
  },
  button: {
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
    height: '2rem',
    width: '2rem',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
}));
const Filter = ({ filterText, onFilter, onClear, filterField }) => {
  const classes = useLocalStyles();
  return (
    <>
      <input
        id='search'
        type='text'
        placeholder={`Filter By ${filterField}`}
        value={filterText}
        onChange={onFilter}
        className={classes.textInput}
      />
      <Button variant='contained' onClick={onClear} className={classes.button}>
        X
      </Button>
    </>
  );
};

export default Filter;
