export const ENVIRONMENT_VARIABLES = {
  apiGateway: {
    local: 'http://localhost:3000/dev',
    staging: 'https://ttv8g9uacd.execute-api.us-east-1.amazonaws.com/staging',
    prod: 'https://6r5a6padpb.execute-api.us-east-1.amazonaws.com/prod',
  },
};

export const ENVIRONMENTS = {
  LOCAL: 'LOCAL',
  STAGING: 'STAGING',
  PROD: 'PROD',
};

export const HTTP_ACTION = {
  GET: 'GET',
  POST: 'POST',
};
