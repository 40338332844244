import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';

export const CreateBountyForm = ({ register, errors, allowance }) => {
  return (
    <>
      <Box mt={2}>
        <Typography component='h1' variant='h5'>
          {`Create New Bounty (Your Allowance: ${allowance})`}
        </Typography>
      </Box>

      <TextField
        inputRef={register({
          required: 'Bounty Value is required',
          max: allowance,
          min: 1,
        })}
        type='number'
        variant='outlined'
        margin='normal'
        fullWidth
        id='bountyValue'
        label='Bounty Value*'
        name='bountyValue'
        autoFocus
        error={!!errors.bountyValue}
        helperText={
          (errors.bountyValue && errors.bountyValue?.message) ||
          (errors.bountyValue?.type === 'max' && `Cannot be greater than your allowance: ${allowance}`) ||
          (errors.bountyValue?.type === 'min' && 'Must be greater than 0') ||
          'Amount will be deducted from your allowance'
        }
      />

      <TextField
        inputRef={register({
          required: 'Title is required',
        })}
        variant='outlined'
        margin='normal'
        fullWidth
        id='title'
        label='Title*'
        name='title'
        error={!!errors.title}
        helperText={errors.title?.message}
      />

      <TextField
        multiline
        rows={4}
        inputRef={register({
          required: 'Description is required',
        })}
        variant='outlined'
        margin='normal'
        fullWidth
        id='description'
        label='Description*'
        name='description'
        error={!!errors.description}
        helperText={errors.description?.message}
      />

      <TextField
        inputRef={register({})}
        variant='outlined'
        margin='normal'
        fullWidth
        id='jiraTicket'
        label='Jira Ticket'
        placeholder='CPLAT-123'
        name='jiraTicket'
        error={!!errors.jiraTicket}
        helperText={errors.jiraTicket?.message}
      />
    </>
  );
};

export default CreateBountyForm;
