import React, { Fragment } from 'react';
import { Container } from '@material-ui/core';
import useHttpClient from '../../hooks/useHttpClient';
import { Link, useParams } from 'react-router-dom';
import { FilterTable } from '../../components/filterTable';
import Alert from '../../components/alert';
import { useStyles } from '../../hooks/useStyles';
const columns = [
  {
    format: (row) => new Date(row.CreatedAt).toLocaleDateString('en-US'),
    name: 'Date Sent',
    selector: 'CreatedAt',
    sortable: true,
    maxWidth: '10rem',
    minWidth: '7rem',
  },
  {
    cell: (row) => <Link to={`/users/${row.senderID}`}>{row.senderName}</Link>,
    name: 'Sender',
    selector: 'senderName',
    sortable: true,
    maxWidth: '10rem',
    minWidth: '7rem',
  },
  {
    name: 'Amount',
    selector: 'amount',
    sortable: true,
    width: '5rem',
  },
  {
    cell: (row) => {
      const maxUsers = 2;
      const names = row.receiverNames.split(',');
      const ids = row.receiverIds.split(',');

      return ids.map((id, index) => (
        <Fragment key={id}>
          {index < maxUsers && (
            <>
              <Link to={`/users/${id}`}>{names[index]}</Link> <div>&nbsp;</div>
            </>
          )}
          {index !== ids.length - 1 && index === maxUsers - 1 && '...'}
        </Fragment>
      ));
    },
    name: 'Receivers',
    selector: 'receiverNames',
    sortable: true,
    maxWidth: '20rem',
  },
  {
    format: (row) => row?.thankMessage?.replace(/<[^>]+> /gi, ''),
    name: 'Message',
    selector: 'thankMessage',
    sortable: true,
  },
];

const UsersPage = () => {
  const globalStyles = useStyles();
  let { userId } = useParams();
  const { data: transactionsData, loading: transactionsLoading, error: transactionsError } = useHttpClient({
    url: `/users/${userId}/transactions`,
    initialRequest: true,
  });

  const { data: userData, loading: userLoading, error: userError } = useHttpClient({
    url: `/users/${userId}`,
    initialRequest: true,
  });

  return (
    <Container maxWidth='lg'>
      <Alert className={globalStyles['mt-2']} show={transactionsError} severity='error'>
        Error retrieving transactions data.
      </Alert>
      <Alert className={globalStyles['mt-2']} show={userError} severity='error'>
        Error retrieving user account data.
      </Alert>
      <FilterTable
        title={`Transaction Summary for ${userData && userData.name} - Allowance: 
        ${userData && userData.account.allowance} Savings: ${userData && userData.account.savings}`}
        data={transactionsData || []}
        columns={columns}
        loading={transactionsLoading || userLoading}
        defaultSortAsc={false}
        defaultSortField={'CreatedAt'}
      />
    </Container>
  );
};

export default UsersPage;
