import { GoogleOAuthProvider } from '@react-oauth/google';
import jwtDecode from 'jwt-decode';
import React, { useState } from 'react';
import { AuthContext } from '../context/AuthContext';

const clientId = '48459754832-o96o2oi3g52fovs23t4048s106aq86u1.apps.googleusercontent.com';

const AuthProvider = ({ children }) => {
  const [authContext, setAuthContext] = useState({ loggedIn: false, user: null });

  const loginOpts = {
    useOneTap: true,
    auto_select: true,
    clientId,
    onSuccess: (resp) =>
      setAuthContext((prev) => ({
        ...prev,
        loggedIn: true,
        user: { tokenId: resp.credential, ...jwtDecode(resp.credential) },
      })),
    onError: () => {
      setAuthContext((prev) => ({ ...prev, loggedIn: false, user: null }));
    },
    hostedDomain: 'everquote.com',
    isSignedIn: true,
  };

  const logoutOpts = {
    clientId,
    afterLogout: () => setAuthContext((prev) => ({ ...prev, loggedIn: false, user: null })),
  };

  const authContextValue = { ...authContext, setAuthContext, loginOpts, logoutOpts };

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>
    </GoogleOAuthProvider>
  );
};

export default AuthProvider;
