import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { useForm, Controller } from 'react-hook-form';

export const GiveForm = ({
  onSubmit,
  allowance,
  users,
  userData,
  initialCoinValue,
  initialRecipients,
  initialMessage,
}) => {
  const { handleSubmit, register, errors, control, watch } = useForm({
    defaultValues: {
      coinValue: initialCoinValue,
      recipients: initialRecipients,
      message: initialMessage,
    },
  });

  const validateNames = () => {
    let errorMessage;
    if (watch('recipients').length === 0) {
      errorMessage = "Can't send coins to no one";
    } else if (
      watch('recipients').some((user) => {
        return user.id === userData?.id;
      })
    ) {
      errorMessage = "Can't distribute coins to yourself";
    }

    return errorMessage;
  };

  return (
    <>
      <Box mt={2}>
        <Typography component='h1' variant='h5'>
          {`Give Goatcoin (Your Allowance: ${allowance})`}
        </Typography>
      </Box>

      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          inputRef={register({
            required: 'Goatcoin Value is required',
            max: watch('recipients') ? allowance / watch('recipients').length : allowance,
            min: 1,
          })}
          type='number'
          variant='outlined'
          margin='normal'
          fullWidth
          id='coinValue'
          label='Coin Value*'
          name='coinValue'
          autoFocus
          error={!!errors.coinValue}
          helperText={
            (errors.coinValue && errors.coinValue?.message) ||
            (errors.coinValue?.type === 'max' && `Cannot be greater than your allowance: ${allowance}`) ||
            (errors.coinValue?.type === 'min' && 'Must be greater than 0') ||
            'Amount will be deducted from your allowance'
          }
        />

        <Controller
          onChange={([, data]) => data}
          name={`recipients`}
          control={control}
          rules={{ validate: validateNames, required: 'Recipient(s) is required' }}
          render={({ onChange, ...props }) => (
            <Autocomplete
              autoHighlight
              multiple
              options={users}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              margin='normal'
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Choose recipient(s)*'
                  variant='outlined'
                  fullWidth
                  margin='normal'
                  error={!!errors.recipients}
                  helperText={errors.recipients && errors.recipients?.message}
                />
              )}
              onChange={(_, data) => onChange(data)}
              {...props}
            />
          )}
        />

        <TextField
          inputRef={register({
            required: 'Message is required',
          })}
          variant='outlined'
          margin='normal'
          fullWidth
          id='message'
          label='Thank you message*'
          name='message'
          error={!!errors.message}
          helperText={errors.message?.message}
        />
        <Button type='submit' color='primary' autoFocus>
          Submit
        </Button>
      </form>
    </>
  );
};

export default GiveForm;
