import React from 'react';
import { Box, Card, CardContent, Fab, Grid, Tooltip } from '@material-ui/core';
import BountyCard from './BountyCard';
import AddIcon from '@material-ui/icons/Add';

const BountyCards = ({ bounties, create, complete, donate, user }) => {
  const bountiesLength = bounties && bounties.length ? bounties.length : 0;

  return (
    <Box mt={2}>
      <Grid container spacing={2}>
        <Grid style={{ textAlign: 'center' }} item xs={12 / (bountiesLength + 1 >= 3 ? 3 : bountiesLength + 1)}>
          <Card>
            <CardContent>
              <Grid
                container
                spacing={0}
                direction='column'
                alignItems='center'
                justify='center'
                style={{ minHeight: '100%' }}>
                <Grid item xs={12}>
                  <Tooltip title='Create Bounty' aria-label='create bounty'>
                    <Fab color='primary' onClick={create}>
                      <AddIcon />
                    </Fab>
                  </Tooltip>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {bounties?.map((bounty) => {
          return (
            <Grid key={bounty.ID} item xs={12 / (bountiesLength + 1 >= 3 ? 3 : bountiesLength + 1)}>
              <BountyCard bounty={bounty} complete={complete} donate={donate} user={user} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default BountyCards;
