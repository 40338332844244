import React from 'react';
import { Box, Grid } from '@material-ui/core';
import RewardCard from './RewardCard';

const RewardCards = ({ rewards, redeem, savings }) => {
  return (
    <Box mt={2}>
      <Grid container spacing={2}>
        {rewards?.map((reward) => (
          <Grid key={reward.id} item xs={12 / (rewards.length >= 3 ? 3 : rewards.length)}>
            <RewardCard reward={reward} redeem={redeem} savings={savings} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default RewardCards;
