import React from 'react';
import { Paper, useTheme } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import { useStyles } from '../../hooks/useStyles';
import Filter from './Filter';
import Loader from '../loader';

export const FilterTable = ({
  title,
  data,
  loading,
  filter,
  columns,
  filterFunction,
  defaultSortField,
  defaultSortDir = 'asc',
  filterField,
  SubHeaderComponent,
  ...rest
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

  const filteredItems = (filter && data && data.filter((item) => filterFunction(item, filterText))) || [];

  const subHeaderFilter = React.useMemo(() => {
    const handleClear = () => {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText('');
    };

    return (
      <>
        {SubHeaderComponent && <SubHeaderComponent />}
        {filter && (
          <Filter
            onFilter={(e) => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
            filterField={filterField}
          />
        )}
      </>
    );
  }, [SubHeaderComponent, filter, filterField, filterText, resetPaginationToggle]);

  return (
    <Paper elevation={5} className={styles.paper}>
      <DataTable
        title={title}
        columns={columns}
        data={(filter && filteredItems) || data}
        defaultSortField={defaultSortField}
        defaultSortAsc={defaultSortDir === 'asc'}
        pagination
        paginationResetDefaultPage={resetPaginationToggle}
        subHeader={filter || !!SubHeaderComponent}
        subHeaderComponent={subHeaderFilter}
        progressPending={loading}
        progressComponent={<Loader loading={loading} size={100} color={theme.palette.primary.main} />}
        {...rest}
      />
    </Paper>
  );
};
