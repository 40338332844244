import { ENVIRONMENT_VARIABLES, ENVIRONMENTS } from './constants';

export const getEnvironment = () => {
  const hostname = window.location.hostname;
  if (hostname.indexOf('localhost') > -1 && !process.env.REACT_APP_ENV_OVERRIDE) {
    return ENVIRONMENTS.LOCAL;
  } else if (hostname.indexOf('staging') > -1) {
    return ENVIRONMENTS.STAGING;
  } else {
    return ENVIRONMENTS.PROD;
  }
};

export const getEnvVariable = (key) => {
  const variablesMap = ENVIRONMENT_VARIABLES[key];

  switch (getEnvironment()) {
    case ENVIRONMENTS.LOCAL:
      return variablesMap.local;
    case ENVIRONMENTS.STAGING:
      return variablesMap.staging;
    default:
      return variablesMap.prod;
  }
};
