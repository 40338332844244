import React from 'react';
import Loader from '../loader';
import { Box, Grid, useTheme } from '@material-ui/core';
import { CoinCard } from './CoinCard';

const MAX_COINS = 75;

export const CoinCount = ({ allowance, loading, savings, userId }) => {
  const theme = useTheme();

  return (
    <Loader color={theme.palette.primary.main} loading={loading} size={100}>
      <Box mt={4}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CoinCard
              progressPercentage={Math.min(Math.floor((allowance / MAX_COINS) * 100), 100)}
              subTitle='Your Allowance'
              total={allowance}
              link={`/users/${userId}`}
            />
          </Grid>

          <Grid item xs={6}>
            <CoinCard progressColor='secondary' subTitle='Your Savings' total={savings} link={'/redeem'} />
          </Grid>
        </Grid>
      </Box>
    </Loader>
  );
};
