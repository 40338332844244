import React, { useCallback, useMemo } from 'react';
import Alert from '../../components/alert';
import clsx from 'clsx';
import useHttpClient from '../../hooks/useHttpClient';
import { Container, makeStyles, Paper, Typography, useTheme } from '@material-ui/core';
import Loader from '../../components/loader';
import { useStyles } from '../../hooks/useStyles';
import GiveForm from '../../components/forms/giveForm';
import { useAuth } from '../../hooks/useAuth';
import { HTTP_ACTION } from '../../utils/constants';
import { useSearchParams } from 'react-router-dom';

const useLocalStyles = makeStyles((theme) => ({
  infoPaper: { paddingTop: '1rem' },
}));

const GivePage = () => {
  const classes = useLocalStyles();
  const theme = useTheme();
  const globalStyles = useStyles();

  const { user } = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    httpRequest: getUser,
    data: userData,
    loading: loadingUser,
    error: userError,
  } = useHttpClient({
    url: `/users?email=${user.email}`,
    initialRequest: true,
  });
  const {
    data: allUsersData,
    loading: loadingAllUsers,
    error: allUsersError,
  } = useHttpClient({
    url: `/users`,
    initialRequest: true,
  });

  const handleGiveCoinsSuccess = () => {
    setSearchParams(undefined);
    getUser({});
  };
  const {
    httpRequest: giveCoinsRequest,
    loading: givingCoins,
    success: giveCoinsSuccess,
    error: giveCoinsError,
  } = useHttpClient({
    url: '/give',
    httpAction: HTTP_ACTION.POST,
    success: handleGiveCoinsSuccess,
  });

  const getUsersFromUserIds = useCallback(
    (ids) => {
      return ids ? allUsersData?.filter((user) => ids.includes(user.id)) : undefined;
    },
    [allUsersData],
  );

  const coinValue = searchParams.get('coinValue') || undefined;
  const recipients = useMemo(
    () => getUsersFromUserIds(searchParams.get('recipientIds')?.split(',')) || undefined,
    [getUsersFromUserIds, searchParams],
  );
  const message = searchParams.get('message') || undefined;

  const escapeHtml = (unsafe) => {
    return unsafe
      .replaceAll('&', '&amp;')
      .replaceAll('<', '&lt;')
      .replaceAll('>', '&gt;')
      .replaceAll('"', '&quot;')
      .replaceAll("'", '&#039;');
  };

  const giveCoins = useCallback(
    async (formVals) => {
      await giveCoinsRequest({
        requestBody: {
          amount: parseInt(formVals.coinValue),
          receiverIds: formVals.recipients.map((recipient) => recipient.id),
          thankMessage: escapeHtml(formVals.message),
        },
      });
    },
    [giveCoinsRequest],
  );

  return (
    <Container maxWidth='md'>
      <Alert className={globalStyles['mt-2']} show={giveCoinsSuccess} severity='success'>
        Success giving goatcoin! ✧˖°.☆.。.:*・°☆.。.:*
      </Alert>
      <Alert className={globalStyles['mt-2']} show={giveCoinsError} severity='error'>
        Error giving Coins.
      </Alert>
      <Alert className={globalStyles['mt-2']} show={userError || allUsersError} severity='error'>
        Error retrieving user data.
      </Alert>
      <Paper className={clsx(globalStyles.paper, classes.infoPaper)}>
        <Typography component={'p'}>Give kudos to anyone easily!</Typography>
        <a href='https://myquote.atlassian.net/wiki/spaces/GOATC/overview'>About Goatcoin</a>
      </Paper>
      <Loader loading={givingCoins || loadingUser || loadingAllUsers} size={100} color={theme.palette.primary.main}>
        <Paper className={clsx(globalStyles.paper, classes.infoPaper)}>
          <GiveForm
            allowance={userData && userData.length === 1 ? userData[0].account.allowance : 0}
            userData={userData && userData[0]}
            users={allUsersData?.sort((a, b) => (a?.name?.toLowerCase() > b?.name?.toLowerCase() ? 1 : -1))}
            onSubmit={giveCoins}
            initialCoinValue={coinValue}
            initialRecipients={recipients}
            initialMessage={message}
          />
        </Paper>
      </Loader>
    </Container>
  );
};

export default GivePage;
