import React from 'react';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import { Paper } from '@material-ui/core';
import { useStyles } from '../../hooks/useStyles';

const RewardCard = ({ reward, redeem, savings }) => {
  const styles = useStyles();

  return (
    <Paper className={styles.paper} elevation={5}>
      <CardContent>
        <Typography variant='h5' component='h2'>
          {reward.title}
        </Typography>
        <Typography>{reward.description}</Typography>
      </CardContent>
      <CardActions>
        <Button
          disabled={savings < reward.cost}
          onClick={() => redeem(reward)}
          size='small'
          variant='contained'
          color='primary'>
          Redeem ({reward.cost})
        </Button>
      </CardActions>
    </Paper>
  );
};

export default RewardCard;
