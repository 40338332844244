import React from 'react';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import { Avatar, Box, Card, CardHeader, makeStyles, Tooltip } from '@material-ui/core';

const useLocalStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: '#4d78bc',
  },
  donate: {
    color: theme.palette.getContrastText('#008000'),
    backgroundColor: '#008000',
  },
}));

const BountyCard = ({ bounty, complete, donate, user }) => {
  const classes = useLocalStyles();

  const completeDisabled = bounty.createdBy !== user.id;

  return (
    <Card>
      <CardHeader
        avatar={
          <Tooltip title={bounty?.creator?.name || 'unknown'}>
            <Avatar aria-label='creator' className={classes.avatar}>
              {bounty?.creator?.name?.substring(0, 1).toUpperCase()}
            </Avatar>
          </Tooltip>
        }
        title={bounty.title}
        subheader={
          <a
            href={`https://myquote.atlassian.net/browse/${bounty.ticketNumber}`}
            target='_blank'
            rel='noopener noreferrer'>
            {bounty.ticketNumber}
          </a>
        }
        action={
          <Typography variant='h6' component='p'>
            {bounty.value}
          </Typography>
        }
      />

      <CardContent>
        <Typography>{bounty.description}</Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Tooltip title={completeDisabled ? 'Can only be marked complete by creator' : ''}>
          {/* extra div is so tooltip shows when button disabled */}
          <div>
            <Button
              disabled={completeDisabled}
              onClick={() => complete(bounty)}
              size='small'
              variant='contained'
              color='primary'>
              Mark Complete...
            </Button>
          </div>
        </Tooltip>

        <Box ml='auto'>
          <Button className={classes.donate} onClick={() => donate(bounty)} size='small' variant='contained'>
            Donate...
          </Button>
        </Box>
      </CardActions>
    </Card>
  );
};

export default BountyCard;
