import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { makeStyles } from '@material-ui/core';

const useLocalStyles = makeStyles((theme) => ({
  centered: {
    textAlign: 'center',
    paddingTop: theme.spacing(2),
  },
}));

const Loader = ({ loading, children, ...rest }) => {
  const classes = useLocalStyles();
  return loading ? (
    <div className={classes.centered}>
      <ClipLoader loading={loading} {...rest} />
    </div>
  ) : (
    children
  );
};

export default Loader;
