import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const ProtectedRoute = ({ render: C, ...routeProps }) => {
  const { loggedIn } = useAuth();
  let location = useLocation();

  return loggedIn ? (
    <Outlet />
  ) : (
    <Navigate to={`/login?redirect=${location.pathname}${encodeURIComponent(location.search)}`} />
  );
};

export default ProtectedRoute;
