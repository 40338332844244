import React from 'react';
import { Grid, LinearProgress, Link, Paper, Typography } from '@material-ui/core';
import { useStyles } from '../../hooks/useStyles';
import CountUp from 'react-countup';
import { NavLink } from 'react-router-dom';

export const CoinCard = ({ subTitle, total, progressColor = 'primary', progressPercentage = 100, link }) => {
  const styles = useStyles();

  return (
    <Link component={NavLink} to={link}>
      <Paper className={styles.paper} elevation={5}>
        <Grid container spacing={4} wrap='nowrap'>
          <Grid item xs={12}>
            <Typography align='center' variant='h4'>
              <CountUp end={total || 0} />
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom align='center' display='block' variant='caption'>
            {subTitle}
          </Typography>
          <LinearProgress color={progressColor} value={progressPercentage || 0} variant='determinate' />
        </Grid>
      </Paper>{' '}
    </Link>
  );
};
