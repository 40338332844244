import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useForm } from 'react-hook-form';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const FormModal = ({
  FormComponent,
  formProps,
  isOpen,
  handleClose,
  onSubmit,
  cancelText = 'Cancel',
  submitText = 'Submit',
}) => {
  const { handleSubmit, register, errors, control, watch } = useForm();

  return (
    <Dialog
      disableBackdropClick
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <FormComponent register={register} errors={errors} control={control} watch={watch} {...formProps} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            {cancelText}
          </Button>
          <Button type='submit' color='primary' autoFocus>
            {submitText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default FormModal;
