import React from 'react';
import Routes from '../routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Header from '../components/header';
import AuthProvider from '../providers/authProvider';

const palette = {
  primary: { main: '#205BB9' },
  secondary: { main: '#ed7119' },
};

const theme = createMuiTheme({ palette });

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Router>
          <Header />
          <Routes />
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
