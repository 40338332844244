import React, { useEffect, useState } from 'react';
import { AppBar, Link, makeStyles, Toolbar, Avatar } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { NavLink } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import logo from '../../assets/logo.png';
import { useAuth } from '../../hooks/useAuth';
import { googleLogout } from '@react-oauth/google';

const useLocalStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'rgb(244, 246, 249)',
  },
  title: {
    flexGrow: 1,
  },
  activeLink: {
    color: theme.palette.primary.main,
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    padding: '5px 10px 5px 10px',
    textDecoration: 'none',
  },
  inactiveLink: {
    color: theme.palette.primary.main,
    padding: '5px 10px 5px 10px',
    textDecoration: 'none',
  },
  headerLink: {
    color: theme.palette.primary.main,
    padding: '5px 10px 7px 10px',
    cursor: 'pointer',
  },
}));

const Index = () => {
  const { loggedIn, logoutOpts, user } = useAuth();
  const classes = useLocalStyles();
  const [profilePicture, setProfilePicture] = useState(null);
  const [userName, setUserName] = useState('');

  useEffect(() => {
    if (!user) return;

    setProfilePicture(user.picture);
    setUserName(user.name);
  }, [user]);

  const logout = () => {
    googleLogout();
    logoutOpts.afterLogout();
  };

  return (
    <AppBar position='static' className={classes.appBar}>
      <Toolbar>
        <div className={classes.title}>
          <RouterLink to='/'>
            <img src={logo} alt='Everquote logo' width='160' />
          </RouterLink>
        </div>

        <Box className={classes.headerLink}>
          <NavLink to={'/'} className={({ isActive }) => (isActive ? classes.activeLink : classes.inactiveLink)}>
            Home
          </NavLink>
        </Box>
        <Box className={classes.headerLink}>
          <NavLink to={'/give'} className={({ isActive }) => (isActive ? classes.activeLink : classes.inactiveLink)}>
            Give
          </NavLink>
        </Box>
        <Box className={classes.headerLink}>
          <NavLink
            to={'/bounties'}
            className={({ isActive }) => (isActive ? classes.activeLink : classes.inactiveLink)}>
            Bounties
          </NavLink>
        </Box>
        <Box className={classes.headerLink}>
          <NavLink to={'/redeem'} className={({ isActive }) => (isActive ? classes.activeLink : classes.inactiveLink)}>
            Redeem
          </NavLink>
        </Box>

        {loggedIn && (
          <Link className={classes.headerLink} onClick={logout}>
            Logout
          </Link>
        )}

        {loggedIn && <Avatar alt={userName} src={profilePicture} />}
      </Toolbar>
    </AppBar>
  );
};

export default Index;
