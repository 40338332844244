import React from 'react';
import { Navigate, Route, Routes as Switch } from 'react-router-dom';

import BountiesPage from '../pages/Bounties';
import GivePage from '../pages/Give';
import HomePage from '../pages/Home';
import { LoginPage } from '../pages/Login';
import PageNotFound from '../pages/PageNotFound';
import RedeemPage from '../pages/Redeem';
import UsersPage from '../pages/Users';
import ProtectedRoute from './ProtectedRoute';

const Routes = () => (
  <Switch>
    <Route path='/' element={<ProtectedRoute />}>
      <Route path='/' element={<HomePage />} />
    </Route>
    <Route path='/login' element={<LoginPage />} />
    <Route path='/users/:userId' element={<ProtectedRoute />}>
      <Route path='/users/:userId' element={<UsersPage />} />
    </Route>
    <Route path='/bounties' element={<ProtectedRoute />}>
      <Route path='/bounties' element={<BountiesPage />} />
    </Route>
    <Route path='/give' element={<ProtectedRoute />}>
      <Route path='/give' element={<GivePage />} />
    </Route>
    <Route path='/redeem' element={<ProtectedRoute />}>
      <Route path='/redeem' element={<RedeemPage />} />
    </Route>
    <Route path='/page-not-found' element={<PageNotFound />} />
    <Route path='*' element={<Navigate to='/page-not-found' replace />} />
  </Switch>
);

export default Routes;
