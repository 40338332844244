import { useContext, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';

export const useAuth = () => {
  const authContext = useContext(AuthContext);
  const expiresAt = authContext?.user?.exp * 1000; // convert to milliseconds

  useEffect(() => {
    if (expiresAt && expiresAt <= Date.now()) {
      console.log('user token expired');
      authContext.setAuthContext({ loggedIn: false, user: null });
    }
  });

  return authContext;
};
